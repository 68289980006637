import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Provide an MBUI compatible Carousel element.
 */
@Component({
  selector: 'app-mbui-tabs',
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss',
})
export class MBUITabsComponent implements AfterViewInit, AfterViewChecked {
  @Input() public compactTabs = false;
  @Output() public tabIndexChanged = new EventEmitter<number>();
  @ViewChild('tabsContent') public content?: ElementRef<HTMLDivElement>;
  /**
   * List of indexes of elements inside the carousel.
   * For 4 content elements, `indexes` would be `[0, 1, 2, 3]`.
   *
   * This is needed (instead of a plain number) to be able to use it to render
   * corresponding elements in the Angular template.
   */
  public indexes?: number[];
  public titles?: string[];
  public currentTabIndex = 0;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly logger: NGXLogger,
  ) { }

  public ngAfterViewInit(): void {
    this.logger.debug(
      'Tabs view initialized',
      this.content?.nativeElement,
      this.content?.nativeElement.children.length,
    );

    if (this.content) {
      // Generate list of indexes, e. g. [0, 1, 2, 3].
      this.indexes = Array.from(Array(this.content.nativeElement.children.length).keys());
      this.titles = Array.from(this.content.nativeElement.children).map((htmlElement) => htmlElement.getAttribute('data-tabtitle') ?? '-');

      this.switchToTab(0);
    }

    // Explicitly detect changes because the interface changed after view init.
    this.cdRef.detectChanges();
  }

  public ngAfterViewChecked(): void {
    if (this.indexes && this.indexes.length !== this.content?.nativeElement.children.length) {
      this.logger.debug('Number of carousel elements changed', this.indexes.length, this.content?.nativeElement.children.length);

      this.indexes = Array.from(Array(this.content?.nativeElement.children.length).keys());
      this.currentTabIndex = Math.min(this.currentTabIndex, this.indexes.length - 1);

      // Explicitly detect changes because the interface changed after view checked.
      this.cdRef.detectChanges();
    }
  }

  /**
   * Scroll to the corresponding element of the given `index` in `#scrollableContent`.
   *
   * @param index - Element index to scroll to
   */
  public switchToTab(tabIndex: number): void {
    if (!this.content) {
      this.logger.warn('Cannot switch to tab index because content is not initialized', this.content);
      return;
    }

    // Emit event if the tab index changed.
    if (this.currentTabIndex !== tabIndex) {
      this.tabIndexChanged.emit(tabIndex);
    }

    this.currentTabIndex = tabIndex;

    Array.from(this.content.nativeElement.children).forEach((contentElement, index) => {
      if (index === this.currentTabIndex) {
        contentElement.classList.remove('d-none');
      } else {
        contentElement.classList.add('d-none');
      }
    });
  }
}
